import React, { useContext } from "react"
import PropTypes from "prop-types"
import Navbar from "./Navbar"
import Footer from "./Footer"
import Sidebar from "./Sidebar"
//import { useStaticQuery, graphql } from "gatsby"
// import { GatsbyContext } from "../context/context"

const Layout = ({ location, children }) => {
  //console.log("Layout location=",location)
  // const { isSidebarOpen } = useContext(GatsbyContext)
  //const data = useStaticQuery(graphql`
  //  query SiteTitleQuery {
  //    site {
  //      siteMetadata {
  //        title
  //      }
  //    }
  //  }
  //`)

  return (
    <>
      <Navbar location={location} />
      {/* {isSidebarOpen && <Sidebar />} */}
      {children}
      <Footer />
    </>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
