import React, { useState, useEffect, useContext } from "react"
import styled from "styled-components"
// import logo from "../images/kim-and-hwang-logo-v1-3.png"
//import { GoThreeBars } from "react-icons/go"
import { Link } from "gatsby"
import Image from "gatsby-image"
import { useStaticQuery, graphql } from "gatsby"
import { GatsbyContext } from "../context/context"
import LanguageSwitch from "./LanguageSwitch"
import sublinks_ko from "../constants/links-ko"
import sublinks from "../constants/links"

const query = graphql`
  {
    logo: file(relativePath: { eq: "kim-and-hwang-logo-v1-3.png" }) {
      childImageSharp {
        fixed(height: 50) {
          ...GatsbyImageSharpFixed
        }
      }
    }
    logo_w: file(
      relativePath: { eq: "kim-and-hwang-data-science-logo-whitetext.png" }
    ) {
      childImageSharp {
        fixed(height: 50) {
          ...GatsbyImageSharpFixed
        }
      }
    }
  }
`

const Navbar = ({ location }) => {
  //console.log("Navbar location=",location)
  const {
    logo: {
      childImageSharp: { fixed: fixedLogo },
    },
    logo_w: {
      childImageSharp: { fixed: fixedLogo_w },
    },
  } = useStaticQuery(query)

  const {
    defaultLang,
    setDefaultLang,
    language,
    setLanguage,
    links,
    setLinks,
  } = useContext(GatsbyContext)
  //console.log("Navbar language=", language)
  useEffect(() => {
    //console.log("Navbar useEffect language=", language)
    if (language === "ko") {
      setLinks(sublinks_ko)
    } else {
      setLinks(sublinks)
    }
  }, [language])

  return (
    <Wrapper>
      <div className="nav-center">
        <div className="nav-header">
          <Link to="/">
            <Image
              fixed={
                location.pathname === "/" ||
                location.pathname === "/en" ||
                location.pathname === "/en/"
                  ? fixedLogo_w
                  : fixedLogo
              }
              className="knh-logo"
            />
          </Link>
          {/* {!isSidebarOpen && (
            <button
              className={`toggle-btn ${
                location.pathname === "/" ? "nav-home" : ""
              }`}
              onClick={showSidebar}
            >
              <GoThreeBars />
            </button>
          )} */}
        </div>
        <ul
          className={`nav-links ${
            location.pathname === "/" ||
            location.pathname === "/en" ||
            location.pathname === "/en/"
              ? "nav-home"
              : ""
          }`}
        >
          {links.map(link => {
            const { id, text, textEng, url } = link
            return (
              <li key={id}>
                <Link to={url}>
                  <span className="nav-kr">{text}</span>
                  <span className="nav-eng">{textEng}</span>
                </Link>
              </li>
            )
          })}
        </ul>
        <div className="nav-lang">
          <LanguageSwitch checked={language === "en"}></LanguageSwitch>
        </div>
      </div>
    </Wrapper>
  )
}

const Wrapper = styled.nav`
  position: relative;
  background: transparent;
  z-index: 1;
  height: 5rem;
  display: flex;
  align-items: center;
  justify-content: center;
  .nav-kr {
    display: none;
  }
  .nav-eng {
    display: block;
    font-size: 1rem;
  }
  .knh-logo {
    margin-top: 1rem;
  }
  .nav-center {
    width: 90vw;
    margin: 0 auto;
    max-width: var(--max-width);
    display: grid;
    grid-template-columns: auto 1fr auto;
    justify-items: flex-end;
    align-items: center;
  }
  .nav-links {
    display: grid;
    grid-template-columns: repeat(3, auto);
    column-gap: 1.5rem;
    justify-content: flex-end;
    li {
      margin: 0 0.5rem;
    }
    a {
      color: var(--clr-black);
      font-family: var(--ff-primary);
      font-size: 1.25rem;
      text-transform: capitalize;
    }
  }
  .nav-home {
    a {
      color: var(--clr-white);
    }
  }
  .nav-header {
    color: var(--clr-white);
    display: flex;
    align-items: center;
    justify-content: space-between;
    img {
      width: auto;
    }
    .toggle-btn {
      display: none;
      // width: 3.5rem;
      // height: 2.25rem;
      // display: flex;
      // align-items: center;
      // justify-content: center;
      // font-size: 2.5rem;
      // background: transparent;
      // border: transparent;
      // color: var(--clr-black);
      // cursor: pointer;
    }
  }
  // .nav-links {
  //   display: none;
  // }
  .nav-lang {
    width: 5rem;
    // height: 2.25rem;
    display: flex;
    margin: 0 auto;
    align-items: center;
    justify-content: center;
  }
  @media (min-width: 768px) {
    .nav-kr {
      display: block;
    }
    .nav-eng {
      display: none;
    }
    // .nav-center {
    //   display: grid;
    //   grid-template-columns: auto 1fr;
    //   justify-items: flex-end;
    //   align-items: center;
    // }
    // .nav-links {
    //   display: grid;
    //   grid-template-columns: repeat(3, auto);
    //   column-gap: 1.5rem;
    //   justify-content: flex-end;
    //   li {
    //     margin: 0 0.5rem;
    //   }
    //   a {
    //     color: var(--clr-black);
    //     font-family: var(--ff-primary);
    //     font-size: 1.25rem;
    //   }
    // }
    // .nav-home {
    //   a {
    //     color: var(--clr-white);
    //   }
    // }
  }
`

export default Navbar
