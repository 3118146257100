import React, { useState, useContext, useEffect } from "react"
import { navigate } from "gatsby"
import styled from "styled-components"
import { GatsbyContext } from "../context/context"

const LanguageSwitch = ({ checked = false, color = "#378ccf" }) => {
  //console.log("LanguageSwitch checked=", checked)
  const { defaultLang, setDefaultLang, language, setLanguage } = useContext(
    GatsbyContext
  )

  const [toggle, setToggle] = useState(checked)
  //useEffect(() => {
  //  console.log("LanguageSwitch useEffect toggle=", toggle)
  //  console.log("LanguageSwitch useEffect language=", language)
  //}, [language, toggle])

  const handleToggleSwitch = e => {
    e.preventDefault()
    //console.log(e.target)
    const newToggle = !toggle
    const newLang = newToggle ? "en" : "ko"
    //console.log("LanguageSwitch handleToggleSwitch newToggle=", newToggle)
    //console.log("LanguageSwitch handleToggleSwitch newLang=", newLang)
    setLanguage(newLang)
    setToggle(newToggle)
  }

  return (
    <Switch {...{ toggle }} onClick={handleToggleSwitch}>
      <Input {...{ color }} type="checkbox" defaultChecked={toggle} disabled />
      <Slider {...{ toggle, color }} />
    </Switch>
  )
}

const Slider = styled.span`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: ${({ toggle, color }) => color};
  border-radius: 15px;
  transition: 0.4s;

  &:before {
    content: "";

    position: absolute;
    left: 3px;
    bottom: 2.5px;

    width: 21px;
    height: 21px;
    border-radius: 100%;

    background-color: ${({ toggle, color }) => "white"};

    transition: 0.4s;
  }
`

const Input = styled.input`
  &:checked + ${Slider}:before {
    transform: translateX(29px);
  }
`

const Switch = styled.label`
  position: relative;
  display: inline-block;
  width: 56px;
  height: 26px;
  background-color: ${({ toggle, color }) => "white"};
  border-radius: 50%;
  transition: 0.4s;
  cursor: pointer;
  box-shadow: var(--light-shadow);

  & ${Input} {
    opacity: 0;
    width: 0;
    height: 0;
  }

  & :after {
    content: "${({ toggle }) => (toggle ? "En" : "Ko")}";
    color: white;
    position: absolute;
    top: 1px;
    left: ${({ toggle }) => (toggle ? "8px" : "28px")};
    bottom: 2px;
    font-size: 16px;
  }
`

export default LanguageSwitch
